
<section>
    <section>
        <div class="container-video">
            <video width="100%" loop muted playsinline id="videoElement">
                <source src="../../../../assets/img/video_con_logo_mentanalytics.mp4" type="video/mp4">
            </video>
            <div class="play-button-container" (click)="playVideo()">
                <img class="play-button-svg" src="assets/img/circle-play-blanco.png" alt="Reproducir video">
            </div>
        </div>
    </section>

<section class="features-area pt-100 pb-100 bg-fffff" #target>
    <div class="container max-width-1290">
        <div class="section-title">
            <h2>Nuestros Servicios</h2>
        </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-features-box">
                            <div class="icon">
                                <i class='bx bx-line-chart bg-ffb700 blt-radius-0'></i>
                            </div>
                            <h3>Data Science</h3>
                            <p class="padding-p">¿Necesitas saber como se comportan los datos de tu empresa, clientes, proveedores o lo que se te venga a la mente para tomar las decisiones que más se alineen con tus objetivos? Te guiamos desde la fase de toma de datos, su análisis hasta su visualización y disponibilización.</p>
                            <br>
                            <a routerLink="/servicios" class="read-more">Ver más <i class='bx bx-right-arrow-alt'></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-features-box">
                            <div class="icon">
                                <i class='bx bx-brain bg-00d280 blt-radius-0'></i>
                            </div>
                            <h3>Inteligencia Artificial (IA)</h3>
                            <p class="padding-p">¿Quieres sacarle el máximo provecho a la tecnología y avanzar al próximo nivel? Te ofrecemos soluciones mediante modelos predictivos que aprenden automáticamente mediante patrones complejos para realizar tareas, tomar decisiones, y más!, atrévete a automatizar tus procesos.</p>
                            <br>
                            <a routerLink="/servicios" class="read-more">Ver más <i class='bx bx-right-arrow-alt'></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-features-box">
                            <div class="icon">
                                <i class='bx bx-cloud bg-6610f2 blt-radius-0'></i>
                            </div>
                            <h3>Clouds</h3>
                            <p class="padding-p">¿Necesitas subir información de tu negocio/empresa a la nube? Contamos con experiencia en las nubes más populares del mercado, utilizando la cultura Devops para que todo esté actualizado, seguro, respaldado y portable, teniendo acceso en cualquier momento en cualquier dispositivo</p>
                            <a routerLink="/servicios" class="read-more">Ver más <i class='bx bx-right-arrow-alt'></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-features-box">
                            <div class="icon">
                                <i class='bx bx-code-alt bg-ff612f blt-radius-0'></i>
                            </div>
                            <h3>Desarrollo Aplicaciones</h3>
                            <p class="padding-p">¿Quieres crear una aplicación web, móvil para tu empresa/producto?¿ utilizar realidad aumentada para exhibir tus productos, o quizás realidad virtual? Te ayudamos a crear ese proyecto utilizando las tecnologías que más se acomoden a tus necesidades ,asegurando la seguridad y confiabilidad de tus datos.</p>
                            <a routerLink="/servicios" class="read-more">Ver más <i class='bx bx-right-arrow-alt'></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-features-box">
                            <div class="icon">
                                <i class='bx bx-sitemap'></i>
                            </div>
                            <h3>Diseño de Arquitectura</h3>
                            <p class="padding-p">¿Deseas crear un proyecto desde cero, o reducir costos de servicios utilizados optimizando tu proceso actual? Te brindamos soluciones para que utilices los recursos que realmente necesitas tener para que tu proceso funcione correctamente.</p>
                            <a routerLink="/servicios" class="read-more">Ver más <i class='bx bx-right-arrow-alt'></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-features-box">
                            <div class="icon">
                                <i class='bx bx-conversation bg-ff612f blt-radius-0'></i>
                            </div>
                            <h3>Consultorías</h3>
                            <p class="padding-p">Te asesoramos en diversas áreas, tales como, Data Management, Data Analytics, Machine Learning & Deep Learning, Cloud, Devops, Diseño de Arquitectura y mucho más!!</p>
                           <br>
                            <a routerLink="/servicios" class="read-more">Ver más <i class='bx bx-right-arrow-alt'></i></a>
                        </div>
                    </div>
                </div>
            </div>
        <div class="shape9">
            <img src="assets/img/shape/shape9.png" alt="image">
        </div>
    </div>

</section>

<section class="video-presentation-area pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Nuestra Metodología </h2>
        </div>
        <div class="video-box">
            <img src="assets/img/nuestra_metodologia.png" class="main-image" alt="nuestra_metodología">
            <!--            <a href="https://www.youtube.com/watch?v=0gv7OC9L2s8" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>-->
            <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
            <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
            <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
            <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
            <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
            <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        </div>
        <div class="container pt-100">
            <div class="free-trial-content margen-bloque-contacto">
                <h2>¿Tienes alguna idea en mente?</h2>
                <p>Por que tus ideas crean realidad</p>
                <a routerLink="/contacto" class="default-btn"><i class="bx bxs-hot"></i> Contactanos<span></span></a>
            </div>
        </div>

    </div>
    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
</section>


<section class="free-trial-area pb-100">

    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M4ZNG2LJ"
                      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->
